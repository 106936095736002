/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import {environment} from "./environments/environment";

if (environment.production) {
    Sentry.init({
        dsn: environment.sentryDsn,
        release: environment.version,
        environment: environment.production ? 'production' : 'development',
        integrations: [
        ],
    });
}

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
