import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {BackendService} from './core/services/backend.service';
import {LocalStorageService} from 'ngx-webstorage';
import {NavigationBarComponent} from "./core/components/navigation-bar/navigation-bar.component";
import {AppLocalStateService} from "./core/services/app-local-state.service";
import {TranslateService} from "@ngx-translate/core";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {preferred_lang} from "./core/signals/translation.signal";
import {environment} from "../environments/environment";
import {NavigationService} from "./core/services/navigation.service";
import {NgxSmartBannerModule, NgxSmartBannerPlatform, NgxSmartBannerService} from "@netcreaties/ngx-smart-banner";
import { version } from "../../version.json";


@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, NavigationBarComponent, NgxSmartBannerModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'Diagnosou';

    @ViewChild("navBar") navBar: NavigationBarComponent | undefined;
    @ViewChild("main") main: ElementRef | undefined;

    constructor(
        private backendService: BackendService,
        private localStorageService: LocalStorageService,
        private translate: TranslateService,
        private renderer: Renderer2,
        private navigation: NavigationService, // Init here to make sure it tracks navigation events from the start
        private smartBanner: NgxSmartBannerService,
        private viewContainerRef: ViewContainerRef,
        public appLocal: AppLocalStateService,
    ) {
        console.log("Diagnosou version", version);

        this.translate.addLangs(['en', 'el']);
        this.translate.setDefaultLang('el');
        this.translate.use(this.localStorageService.retrieve("preferred_lang") || 'el');
        preferred_lang.set(this.localStorageService.retrieve("preferred_lang") || 'el');

        // Add relevant user data to sentry for logging whenever they are updated
        this.appLocal.setupSentryUser();
        this.localStorageService.observe('installationId')
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.appLocal.setupSentryUser());
        this.localStorageService.observe('uid')
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.appLocal.setupSentryUser());
        this.localStorageService.observe('session_token')
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.appLocal.setupSentryUser());

        this.localStorageService.observe("preferred_lang")
            .pipe(takeUntilDestroyed())
            .subscribe((lang: string) => {
                if (lang && this.translate.getLangs().includes(lang)) {
                    this.translate.use(lang);
                    // Workaround for date pipes since LOCALE_ID is not updated dynamically
                    preferred_lang.set(lang);
                }
            });
    }

    ngOnInit() {
        if (!this.localStorageService.retrieve('installationId')) {
            this.backendService.getInstallation().subscribe((response) => {
                if (response.installationId) {
                    this.localStorageService.store('installationId', response.installationId);
                }
            });
        }

        if (environment.production) {
            this._setupMetaPixel();
            this._setupGoogleTagManager();
        }
    }

    ngAfterViewInit() {
        this.appLocal.navBar.set(this.navBar);
        this.appLocal.mainElement.set(this.main);

        this.smartBanner.initialize({
            viewContainerRef: this.viewContainerRef,
            title: 'Diagnosou',
            author: 'REVOTECH L.L.C-FZ',
            icon: {
                ios: 'assets/images/App Logo.svg',
                android: 'assets/images/App Logo.svg',
            },
            hideRating: true,
            daysHidden: 1,
            daysReminder: 1,
            buttonLabel: "Download",
            buttonUrl: {
                ios: environment.iosAppUrl,
                android: environment.androidAppUrl,
            },
            enabledPlatforms: [NgxSmartBannerPlatform.IOS, NgxSmartBannerPlatform.Android]
        })
    }

    private _setupMetaPixel() {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '463168376758892');
            fbq('track', 'PageView');
        `;

        this.renderer.appendChild(document.head, script);

        const noscript = this.renderer.createElement('noscript');
        const img = this.renderer.createElement('img');
        this.renderer.setAttribute(img, 'height', '1');
        this.renderer.setAttribute(img, 'width', '1');
        this.renderer.setAttribute(img, 'style', 'display:none');
        this.renderer.setAttribute(img, 'src', 'https://www.facebook.com/tr?id=463168376758892&ev=PageView&noscript=1');
        this.renderer.appendChild(noscript, img);
        this.renderer.appendChild(document.body, noscript);
    }

    private _setupGoogleTagManager() {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.text = `
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-T43JDZ84');
        `;

        this.renderer.insertBefore(document.head, script, document.head.firstChild);

        const noscript = this.renderer.createElement('noscript');
        const iframe = this.renderer.createElement('iframe');
        this.renderer.setAttribute(iframe, 'height', '0');
        this.renderer.setAttribute(iframe, 'width', '0');
        this.renderer.setAttribute(iframe, 'style', 'display:none;visibility:hidden');
        this.renderer.setAttribute(iframe, 'src', 'https://www.googletagmanager.com/ns.html?id=GTM-T43JDZ84');
        this.renderer.appendChild(noscript, iframe);
        this.renderer.insertBefore(document.body, noscript, document.body.firstChild);
    }

}
