import {ChangeDetectorRef, Component, model, signal} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';

import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NavigationBarComponent} from '../../core/components/navigation-bar/navigation-bar.component';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {TermsCheckboxComponent} from "../../core/components/terms-checkbox/terms-checkbox.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {LabService} from "../../core/services/lab.service";
import {UserService} from "../../core/services/user.service";
import {finalize} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {SessionStorageService} from "ngx-webstorage";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {LoaderComponent} from "../../core/components/loader/loader.component";
import {BackendService} from "../../core/services/backend.service";
import {isNil} from "lodash";

@Component({
    selector: 'app-eligibility',
    standalone: true,
    imports: [NgbModule, CommonModule, NavigationBarComponent, ReactiveFormsModule, MatCheckboxModule, FormsModule, NgOptimizedImage, TermsCheckboxComponent, MatProgressSpinner, TranslateModule, LoaderComponent],
    templateUrl: './eligibility.component.html',
    styleUrl: './eligibility.component.scss'
})
export class EligibilityComponent {

    currentStage = 4;
    amkaForm!: FormGroup;
    ssnAutoVerify?: boolean;

    loading = signal(false);
    readonly checked = model(false);

    constructor(
        private router: Router,
        private cd: ChangeDetectorRef,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private userService: UserService,
        private toastr: ToastrService,
        private session: SessionStorageService,
        private translate: TranslateService,
        private backendService: BackendService,
        public labService: LabService,
    ) {

        if (!this.labService.selectedTests()?.length) {
            this.router.navigate(['/test-select']);
            return;
        }
        if (!this.labService.selectedLab()) {
            this.router.navigate(['/lab-select']);
            return;
        }

        this.loading.set(true);
        this.backendService.nextFlow()
            .pipe(finalize(() => {
                if (isNil(this.ssnAutoVerify)) {
                    this.ssnAutoVerify = false;
                }
                this.loading.set(false)
            }))
            .subscribe(res => {
                switch (res?.['next']) {
                    case "ssn":
                        this.currentStage = 7; // AMKA CHECK
                        this.ssnAutoVerify = true;
                        break;
                    case "book":
                        this.session.store("privateAppointment", true);
                        this.session.clear("currentEligibleSsn");
                        this.labService.rescheduleId.set(null);
                        this.labService.rescheduleReasonId.set(null);
                        this.labService.rescheduleReasonComment.set(null);
                        this.router.navigate(['/appointment']);
                        break;
                    default:
                        this.ssnAutoVerify = false;
                }
            });

        this.amkaForm = this.fb.group({
            amka: ['',
                [Validators.required,
                    Validators.maxLength(11),
                    Validators.minLength(11)
                ]],
            terms: [false, Validators.requiredTrue]
        });

        this.route.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
            this.currentStage = Number(params['currentStage']) || 4;
            if (params['terms']) {
                this.amkaForm.patchValue({terms: true});
                this.router.navigate([], {queryParams: {terms: null}, queryParamsHandling: 'merge'});
            }
        });
    }

    onCurrentStageChange(newStage: number) {
        if (!(newStage === 5 && this.ssnAutoVerify)) {
            this.currentStage = newStage; // Update the currentStage based on the navigation bar input
            return;
        }

        // Handle new flow here where we start by validating ssn instead of checking eligibility criteria
        switch (this.currentStage) {
            case 6:
                this.currentStage = 7; // go to ssn check
                this.cd.markForCheck();
                break;
            case 7:
                this.router.navigate(['/lab-select']); // in this flow, ssn check is the first step, so on back we leave the eligibility screen
                break;
        }
    }

    buttonClick(action: string) {
        if (action === 'yes') {
            this.session.store("privateAppointment", this.currentStage === 6);
            this.labService.rescheduleId.set(null);
            this.labService.rescheduleReasonId.set(null);
            this.labService.rescheduleReasonComment.set(null);
            this.router.navigate(['/appointment']);
        } else if (action === 'no') {
            if (this.currentStage === 6) {
                this.router.navigate(['/'], {queryParams: {back: "true"}});
            }
            this.currentStage = 5;
        } else if (action === 'not-sure') {
            if (this.currentStage === 4) {
                this.currentStage = 5;
            } else if (this.currentStage === 5) {
                this.currentStage = 7;
            }
        } else if (action === 'no-i-dont') {
            this.currentStage = 6;
        }

    }

    allowOnlyNumbers(event: KeyboardEvent) {
        if (["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key)) {
            return;
        }
        if (event.key?.toLowerCase() === "a" && (event.ctrlKey || event.metaKey)) {
            return;
        }

        const charCode = event.which ? event.which : event.keyCode;
        // Allow only numbers (0-9)
        if ((charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
            event.preventDefault();
        }
    }

    submit() {

        if (this.amkaForm.invalid) {
            this.amkaForm.markAllAsTouched();
            return;
        }

        if (!(this.labService.selectedLab()?.id && this.labService.selectedTests()?.length)) {
            return;
        }


        this.loading.set(true);

        if (this.ssnAutoVerify) {
            this.backendService.verifyEligibility(
                this.amkaForm.value.amka,
                this.labService.selectedLab()!.id!,
                this.labService.selectedTests()!.map(test => test.id)!,
            )
                .pipe(finalize(() => this.loading.set(false)))
                .subscribe((res) => {
                    if (!res.success) {
                        this.toastr.error(res.message ?? this.translate.instant('error_amka_unsuccessful_response'));
                        return;
                    }

                    if (!res["eligible"]) {
                        this.session.clear("currentEligibleSsn");
                        this.currentStage = 6;
                        return;
                    }

                    this.session.store("privateAppointment", false);
                    this.session.store("currentEligibleSsn", this.amkaForm.value.amka);
                    this.labService.rescheduleId.set(null);
                    this.labService.rescheduleReasonId.set(null);
                    this.labService.rescheduleReasonComment.set(null);
                    this.router.navigate(['/appointment']);

                });
            return;
        }

        this.userService.sendSsnForValidation(
            this.amkaForm.value.amka,
            this.labService.selectedLab()!.id!,
            this.labService.selectedTests()!.map(test => test.id)!,
        )
            .pipe(finalize(() => this.loading.set(false)))
            .subscribe((res) => {
                if (res.success) {
                    this.router.navigate(['/sms-confirmation'], {
                        queryParams: {
                            message1: this.translate.instant('elig_comp_screen_title'),
                            message2: this.translate.instant('elig_comp_screen_subtitle'),
                        }
                    });
                    return;
                }

                this.toastr.error(res.message ?? this.translate.instant('error_amka_unsuccessful_response'));
            });
    }

}
