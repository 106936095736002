import { version } from "../../version.json";

export const environment = {
    version,
    production: true,
    siteUrl: "https://services1.diagnoseu.tech",
    serverUrl: "https://services1.diagnoseu.tech/api/rest",
    sentryDsn: "https://e49af79183e325ee41320002a393cd3d@o4506263706402816.ingest.us.sentry.io/4508223740837888",
    iosAppUrl: "https://apps.apple.com/gr/app/diagnosou/id6736408183",
    androidAppUrl: "https://play.google.com/store/apps/details?id=com.revotech.diagnosou",
};
